import { useI18n } from 'vue-i18n'
import { getLocalizedServerError } from '@/application/utils/localization'
import { useModal } from '@/application/composables/modal'
import { deleteImessageAuthUser } from '@/application/services/imessage-auth.js'

export const useImessageAuthSession = () => {
  const i18n = useI18n()
  const { openModal } = useModal()

  const deleteImessageUserHandler = (id) => {
    deleteImessageAuthUser(id).then(() => {
      openModal(i18n.t('success'), i18n.t('successRemoved'), () => {
        window.location.reload()
      })
    }).catch(err => {
      const response = err.response
      openModal(i18n.t('error'), getLocalizedServerError(i18n, 'forms.imessageAuth.errors.', response))
    })
  }

  return {
    deleteImessageUserHandler,
  }
}
