<template>
  <div class="page-header-title d-flex justify-content-between w-100 m-b-30">
    <h3 class="f-w-300 d-flex align-items-center m-b-0">{{ $t('imessageAuthUsers') }}</h3>
  </div>

  <paginated-table-wrapper
    v-if="license"
    :license="license"
    :sort="'desc'"
    :request="getUserSessionsRequest"
    v-slot="{ list, isLoading }"
  >
    <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
    <paginated-table :list="list" @delete="deleteImessageUserHandler"></paginated-table>
  </paginated-table-wrapper>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { getUserSessions } from '../../services/imessage-auth.js'
import { store } from '@/application/store'
import { useImessageAuthSession } from '../../composables/imessageAuthUsers.js'
// import { useI18n } from 'vue-i18n'

export default {
  name: 'LookupHistoryPage',
  components: {
    'paginated-table-wrapper': defineAsyncComponent(() => import('@/application/components/elements/PaginatedTable/Wrapper.vue')),
    'paginated-table': defineAsyncComponent(() => import('@/application/components/elements/message-tables/IMessageUsersTable.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  setup() {
    // const i18n = useI18n()
    const license = computed(() => store.getters.getLicense)
    const getUserSessionsRequest = getUserSessions

    const {
      deleteImessageUserHandler,
    } = useImessageAuthSession()

    return {
      license,
      getUserSessionsRequest,
      deleteImessageUserHandler,
    }
  },
}
</script>
